import React from "react"
import CompetenceCard from "../../molecules/competence-card/competence-card"
import SectionTitle from "../../atoms/section-title/section-title"
import "./competences-section.scss"

const CompetencesSection = () => (
  <section className="section competences-section">
    <div className="container">
      <SectionTitle title="compétences" />
      <div className="columns is-centered">
        <CompetenceCard
          className="column is-4-desktop"
          type="administratif"
          redirect="/competences/#administratif"
        />

        <CompetenceCard
          className="column is-4-desktop"
          type="etrangers"
          redirect="/competences/#etrangers"
        />

        <CompetenceCard
          className="column is-4-desktop"
          type="affaires"
          redirect="/competences/#affaires"
        />
      </div>
      <div className="columns is-centered">
        <CompetenceCard
          className="column is-4-desktop"
          type="famille"
          redirect="/competences/#famille"
        />

        <CompetenceCard
          className="column is-4-desktop"
          type="penal"
          redirect="/competences/#penal"
        />

        <CompetenceCard
          className="column is-4-desktop"
          type="travail"
          redirect="/competences/#travail"
        />
      </div>
    </div>
  </section>
)

export default CompetencesSection
