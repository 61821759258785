import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Carousel from "../components/carousel/carousel"
import SEO from "../components/atoms/seo"
import EquipeSection from "../components/molecules/equipe-section/equipe-section"
import CompetencesSection from "../components/organisms/competences-section/competences-section"
import HonorairesSection from "../components/molecules/honoraires-section/honoraires-section"
import PageDivider from "../components/atoms/page-divider/page-divider"
import "./index.scss"

const IndexPage = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(
          filter: { title: { eq: "accueil-description-cabinet" } }
        ) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Layout>
        <SEO title="Mopo Avocats" />
        <section className="section section-carousel">
          <Carousel />
          <hr className="navbar-divider" />
        </section>
        <section className="section section-accueil">
          <div className="container is-fluid">
            <div className="is-family-secondary has-text-center description-accueil">
              {allWordpressPage.edges[0]?.node.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: allWordpressPage.edges[0]?.node.content,
                  }}
                />
              )}
            </div>
          </div>
        </section>
        <PageDivider />
        <EquipeSection />
        <PageDivider />
        <CompetencesSection />
        <PageDivider />
        <HonorairesSection />
      </Layout>
    </>
  )
}

export default IndexPage
