import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import RedirectLink from "../../atoms/redirect-link/redirect-link"
import SectionTitle from "../../atoms/section-title/section-title"
import equipeImg from "./equipe.jpg"
import "./equipe-section.scss"

const EquipeSection = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(
          filter: { title: { eq: "equipe-presentation" } }
        ) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )
  return (
    <section className="section equipe-section">
      <div className="container">
        <SectionTitle title="équipe" />
        <div className="columns">
          <div className="left column is-half-desktop">
            <div className="content">
              <div className="is-family-secondary has-text-right is-size-4">
                <div className="equipe-presentation content"
                  dangerouslySetInnerHTML={{
                    __html: allWordpressPage.edges[0]?.node.content,
                  }}
                />
              </div>

              <RedirectLink className="is-right" link="/equipe" />
            </div>
          </div>
          <div className="right column is-half-desktop">
            <div className="image-wrapper">
              <img src={equipeImg} alt="equipe-section" />
              <div className="img-shadow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EquipeSection
