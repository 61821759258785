import React from "react"
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel"
import slider1 from "./carousel.jpg"
import "../../../node_modules/pure-react-carousel/dist/react-carousel.es.css"
import "./carousel.scss"

const Carousel = () => {
  return (
    <CarouselProvider
      naturalSlideWidth={800}
      naturalSlideHeight={400}
      totalSlides={3}
      className="carousel-wrapper"
    >
      <Slider>
        <Slide index={0}>
          <img src={slider1} alt="slider1" />
        </Slide>
        <Slide index={1}>
          <img src={slider1} alt="slider2" />
        </Slide>
        <Slide index={2}>
          <img src={slider1} alt="slider3" />
        </Slide>
      </Slider>
      <DotGroup className="carousel-navigation" />
    </CarouselProvider>
  )
}

export default Carousel
