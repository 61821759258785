import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SectionTitle from "../../atoms/section-title/section-title"
import RedirectLink from "../../atoms/redirect-link/redirect-link"
import "./honoraires-section.scss"

const HonorairesSection = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(filter: { title: { eq: "honoraires-presentation" } }) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )
  return (
    <section className="section honoraires-section">
      <div className="container">
        <SectionTitle title="honoraires" />
        <div className="content">
          <div className="honoraires-description is-family-secondary has-text-centered is-size-4">
            {allWordpressPage.edges[0]?.node.content && (
              <div
                className="honoraires-content"
                dangerouslySetInnerHTML={{
                  __html: allWordpressPage.edges[0]?.node.content,
                }}
              />
            )}
          </div>

          <RedirectLink className="is-right" link="/honoraires" />
        </div>
      </div>
    </section>
  )
}

export default HonorairesSection
